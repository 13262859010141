<template>
  <div class="dashboard">
    <div class="dashboard__container">
      <div class="dashboard__container--header">
        <h1>Shift Lead Applications</h1>
      </div>
      <button @click="updateApplicants()">Update</button>
      <div class="dashboard__container--body">
        <Loader v-if="!leadApplications || leadApplications.length == 0" />
        <vue-good-table
            :columns="columns"
            :rows="leadApplications"
             styleClass="vgt-table condensed"
            :search-options="{
              enabled: true,
              placeholder: 'Search this table',
            }"
            :pagination-options="{
              enabled: true,
              mode: 'records',
              perPage: 25,
            }"
          >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'created'">
              <span v-if="props.row.created">{{formatDate(props.row.created)}}</span>
            </span>
            <span v-else-if="props.column.field == 'status'">
              <v-select
                label="title" 
                :options="statuses"
                v-model="props.row.status"
                @input="updateStatus(props.row)"
                :clearable="false"
                >
              </v-select>
            </span>
            <span v-else-if="props.column.field == 'link'">
              <router-link :to="`/users/` + props.row.user.id" target="_blank">
                <i class="fas fa-external-link ml-3 mr-3"></i>
              </router-link>
            </span>
            <span v-else-if="props.column.field == 'name'">
              <span v-if="props.row.user.firstName">{{props.row.user.firstName}}</span><span v-if="props.row.user.lastName"> {{props.row.user.lastName}}</span>
            </span>
           
            <span v-else>
              {{props.formattedRow[props.column.field]}}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>


<script>
import * as moment from 'moment'
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import router from '@/router'
const fb = require('../../firebaseConfig.js')

export default {
  name: 'shiftLeads',
  data: () => ({
    statuses: ['New', 'In Review', 'Hired', 'On-Hold', 'Not Hired', 'Hide/Duplicate'],
    columns: [
      {
        label: 'Application Status',
        field: 'status',
        width: '180px',
      },
      {
        label: 'Created',
        field: 'created',
        width: '128px',
      },
      {
        label: 'Name',
        field: 'name',
        width: '180px',
        sortable: false,
      },
      {
        label: 'Link',
        field: 'link',
        width: '60px',
        sortable: false,
      },
      {
        label: 'Email',
        field: 'user.email',
        width: '144px',
      },
      {
        label: 'Phone',
        field: 'user.phone',
        width: '128px',
        sortable: false,
      },
      {
        label: 'Areas',
        field: 'form.areas',
        sortable: false,
      },
    ],
  }),
  created () {
    this.$store.dispatch("getLeadApplications")
    // this.$store.dispatch("updateEventJobNos")
    
  },
  computed: {
    ...mapState(['currentUser', 'userProfile', 'leadApplications']),
  },
  components: {
    Loader,
  },
  methods: {
    updateApplicants() {
      this.leadApplications.forEach(user => {
        console.log(user.id)
        fb.usersCollection.doc(user.user.id).update({
          leadApplicationId: user.id
        })
      })
    },
    async updateStatus(row) {
      await fb.leadApplicationsCollection.doc(row.id).update(row)
      fb.usersCollection.doc(row.user.id).update({
        leadStatus: row.status
      })
    },
    formatDate(q) {
      if(q) {
        const postedDate = new Date(q.seconds) * 1000;
        return moment(postedDate).format('MMMM Do YYYY')
      } else {
        return null
      }
    },
  },
  beforeDestroy() {
    this.$store.dispatch("clearLeadApplications")
  }
}
</script>