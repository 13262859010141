var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dashboard"},[_c('div',{staticClass:"dashboard__container"},[_vm._m(0),_c('button',{on:{"click":function($event){return _vm.updateApplicants()}}},[_vm._v("Update")]),_c('div',{staticClass:"dashboard__container--body"},[(!_vm.leadApplications || _vm.leadApplications.length == 0)?_c('Loader'):_vm._e(),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.leadApplications,"styleClass":"vgt-table condensed","search-options":{
            enabled: true,
            placeholder: 'Search this table',
          },"pagination-options":{
            enabled: true,
            mode: 'records',
            perPage: 25,
          }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'created')?_c('span',[(props.row.created)?_c('span',[_vm._v(_vm._s(_vm.formatDate(props.row.created)))]):_vm._e()]):(props.column.field == 'status')?_c('span',[_c('v-select',{attrs:{"label":"title","options":_vm.statuses,"clearable":false},on:{"input":function($event){return _vm.updateStatus(props.row)}},model:{value:(props.row.status),callback:function ($$v) {_vm.$set(props.row, "status", $$v)},expression:"props.row.status"}})],1):(props.column.field == 'link')?_c('span',[_c('router-link',{attrs:{"to":`/users/` + props.row.user.id,"target":"_blank"}},[_c('i',{staticClass:"fas fa-external-link ml-3 mr-3"})])],1):(props.column.field == 'name')?_c('span',[(props.row.user.firstName)?_c('span',[_vm._v(_vm._s(props.row.user.firstName))]):_vm._e(),(props.row.user.lastName)?_c('span',[_vm._v(" "+_vm._s(props.row.user.lastName))]):_vm._e()]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dashboard__container--header"},[_c('h1',[_vm._v("Shift Lead Applications")])])
}]

export { render, staticRenderFns }